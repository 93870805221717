/*
 * This file is part of Invenio.
 * Copyright (C) 2015, 2017 CERN.
 *
 * Invenio is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2 of the
 * License, or (at your option) any later version.
 *
 * Invenio is distributed in the hope that it will be useful, but
 * WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Invenio; if not, write to the Free Software Foundation, Inc.,
 * 59 Temple Place, Suite 330, Boston, MA 02111-1307, USA.

* In applying this license, CERN does not
* waive the privileges and immunities granted to it by virtue of its status
* as an Intergovernmental Organization or submit itself to any jurisdiction.
*/

// Setting the path to fonts files.
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "~font-awesome/fonts";

// Bootstrap
$brand-primary: #0c63aa;
$gray: #586069;
$bg-gray: #eaecef;

// Brand colors CDS
$light-color: #ffffff;
$cds-primary-color: #111;
$primary-text-color: #212121;
$secondary-text-color: #727272;
$divider-color: #B6B6B6;

// Button
$btn-info-bg: #e7e8ea;
$btn-info-color: #464a4e;
$btn-border-color: #ccc;

// Unicorn colors
$cds-unicorn-blue: #0ff;
$cds-unicorn-pink: #f0f;
$cds-unicorn-color: linear-gradient(-225deg, $cds-unicorn-blue 0%, $cds-unicorn-pink 100%);
$cds-navbar-form-bg: lighten($cds-primary-color, 20%);

// Contrast colors
$cds-contrast-color-1: #F9E791;
$cds-contrast-color-2: #7EC8EF;
$cds-contrast-color-3: #F57365;
$cds-contrast-color-4: #1D69A5;

$primary-color: $brand-primary;
$primary-color-dark: darken($primary-color, 10%);
$primary-color-light: lighten($primary-color, 10%);

$font-size-base: 16px;

// Navbar colors
$navbar-height: 64px;
$navbar-collapse-max-height: 340px;

$navbar-default-bg: #232323;
$navbar-default-border: transparent;
$navbar-default-color: $primary-color;

// Navbar links
$navbar-default-link-color: $light-color;
$navbar-default-link-hover-color: #ddd;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $light-color;
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #fff;
$navbar-default-toggle-border-color: #fff;

// Bliax, remove border radius
$border-radius-small: 3;
$border-radius-base: 3;
$border-radius-large: 3;
