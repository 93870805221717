/*
 * This file is part of Invenio.
 * Copyright (C) 2017 CERN.
 *
 * Invenio is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2 of the
 * License, or (at your option) any later version.
 *
 * Invenio is distributed in the hope that it will be useful, but
 * WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Invenio; if not, write to the Free Software Foundation, Inc.,
 * 59 Temple Place, Suite 330, Boston, MA 02111-1307, USA.

* In applying this license, CERN does not
* waive the privileges and immunities granted to it by virtue of its status
* as an Intergovernmental Organization or submit itself to any jurisdiction.
*/

.bw-0 { border-width: 0; }
.bw-1 { border-width: 1px; }
.bw-2 { border-width: 2px; }
.bw-3 { border-width: 3px; }
.bw-4 { border-width: 4px; }
.bw-5 { border-width: 5px; }

.ba { border-style: solid; }
.bt { border-top-style: solid; }
.br { border-right-style: solid; }
.bb { border-bottom-style: solid; }
.bl { border-left-style: solid; }
.bn { border-style: none; }

// Font weight thin
.fw-t {
  font-weight: thin !important;
}
// Font weight normal
.fw-n {
  font-weight: normal !important;
}
// Font weight bold
.fw-b {
  font-weight: bold !important;
}

// 64px
.f1 { font-size: 44px !important; }
// 36px
.f2 { font-size: 36px !important; }
// 32px
.f3 { font-size: 32px !important; }
// 28px
.f4 { font-size: 28px !important; }
// 25px
.f5 { font-size: 25px !important; }
// 22px
.f6 { font-size: 22px !important; }
// 20px
.f7 { font-size: 20px !important; }
// 16px
.f8 { font-size: 16px !important; }
// 14px
.f9 { font-size: 14px !important; }

.b-r-3 {
  border-radius: 3px !important;
}
