/*
 * This file is part of Invenio.
 * Copyright (C) 2017 CERN.
 *
 * Invenio is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2 of the
 * License, or (at your option) any later version.
 *
 * Invenio is distributed in the hope that it will be useful, but
 * WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Invenio; if not, write to the Free Software Foundation, Inc.,
 * 59 Temple Place, Suite 330, Boston, MA 02111-1307, USA.

* In applying this license, CERN does not
* waive the privileges and immunities granted to it by virtue of its status
* as an Intergovernmental Organization or submit itself to any jurisdiction.
*/

// Backgrounds

// Background normal
// background black
.bg-b {
  background-color: #000 !important
}
.bg-n {
  background-color: $cds-primary-color !important;
}
// Background light
.bg-l {
  background-color: $primary-color-light !important;
}
// Background darker
.bg-d {
  background-color: $primary-color-dark !important;
}
// Background gray
.bg-g {
  background-color: $gray !important;
}
.bg-gl {
  background-color: $bg-gray !important;
}
.bg-warn {
  background-color: #fcf8e3 !important;
}
// Background white
.bg-w {
  background-color: white !important;
}
// Background contrast
.bg-c-1 {
  background-color: $cds-contrast-color-1 !important;
}
.bg-c-2 {
  background-color: $cds-contrast-color-2 !important;
}
.bg-c-3 {
  background-color: $cds-contrast-color-3 !important;
}
.bg-c-4 {
  background-color: $cds-contrast-color-4 !important;
}
// Text white
.t-w {
  color: #fff;
}
// Text blue
.t-b {
  color: $cds-primary-color;
}

// Link white
.l-w {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

// Link blue
.l-b {
  color: $cds-primary-color;
  &:hover {
    color: $cds-primary-color;
  }
}

// Border grey
.bc-g {
  border-color: $gray !important;
}

.bc-gl {
  border-color: $bg-gray !important;
}

.bc-ccc {
  border-color: #ccc !important;
}
