/*
 * This file is part of Invenio.
 * Copyright (C) 2017 CERN.
 *
 * Invenio is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2 of the
 * License, or (at your option) any later version.
 *
 * Invenio is distributed in the hope that it will be useful, but
 * WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Invenio; if not, write to the Free Software Foundation, Inc.,
 * 59 Temple Place, Suite 330, Boston, MA 02111-1307, USA.

* In applying this license, CERN does not
* waive the privileges and immunities granted to it by virtue of its status
* as an Intergovernmental Organization or submit itself to any jurisdiction.
*/

// a - all i.e. top, bottom, left, right
// t - top
// b - bottom
// l - left
// r - right
// x - x axis i.e. left and right
// y - y axis i.e. top and bottom

// m - margin

// 0px
.ma-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// 5px
.ma-5 {
  margin: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

// 10px
.ma-10 {
  margin: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

// 15 px
.ma-15 {
  margin: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px;
}
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

// 20px
.ma-20 {
  margin: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

// 30px
.ma-30 {
  margin: 30px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

// 40px
.ma-40 {
  margin: 40px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

// -20px
.ma--20 {
  margin: -20px !important;
}
.mt--20 {
  margin-top: -20px !important;
}
.mr--20 {
  margin-right: -20px !important;
}
.mb--20 {
  margin-bottom: -20px !important;
}
.ml--20 {
  margin-left: -20px !important;
}
.mx--20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.my--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

// p - padding

// 0px
.pa-0 {
  padding: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

// 5px
.pa-5 {
  padding: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// 10px
.pa-10 {
  padding: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

// 20px
.pa-20 {
  padding: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

// 30px
.pa-30 {
  padding: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

// 40px
.pa-40 {
  padding: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
